@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,700");

:root {
    --color-primary: #00538b;
    --color-secondary: #f1f5f8;
    --color-complimentary: #b8eee4;
    --color-button-bg: #c8b77e;
    --color-button-text: #fff;
    --color-white-text: #fff;
    --color-labels: #6d7b86;
    --color-text: #212529
}

body, input, button {
    font-family: "Raleway", sans-serif;
    font-variant-numeric: lining-nums;
    -webkit-font-smoothing: antialiased;
}

body, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}
